<template>
  <div class="full-area vertically-centered bg-image">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>
    <div class="yet-another-wrapper">
      <transition name="bounce" mode="out-in">
        <div class="delay delay-more image-wrapper" v-if="img">
          <img :src="img">
          <Icons
            v-if="uploadStatus === 'success'"
            class="icon"
            icon="check"
            size="64"
            :color="'rgba(36,93,58,0.9)'"
          ></Icons>
        </div>
      </transition>
    </div>
    <div class="actions-wrapper" v-if="!img">
      <div>
        <input 
          type="file" 
          accept="image/*" 
          @change="handleFile"  
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer; z-index: 2"
        />
        <Icons
          key="take-photo"
          class="icon icon-animate"
          icon="camera-iris"
          size="64"
          :color="'rgba(93,36,102,0.9)'"
          :style="{ opacity: isLocked ? 0.5 : 1 }"
        ></Icons>
        <h3>Seleccionar foto</h3>
      </div>
    </div>
    <div class="actions-wrapper" v-if="img">
      <div @click="img = ''; uploadStatus = '';">
        <Icons
          class="icon icon-animate"
          icon="reload"
          size="64"
          :color="'rgba(93,36,102,0.9)'"
        ></Icons>
        <h3>Seleccionar de nuevo</h3>
      </div>
      <div @click="checkCaptchaAndUpload" :style="{ opacity: isUploading ? 0.5 : 1 }">
        <Icons
          class="icon icon-animate"
          icon="upload"
          size="64"
          :color="'rgba(93,36,102,0.9)'"
        ></Icons>
        <h3>Subir foto</h3>
      </div>
    </div>
    <footer>
      <SkipQuestionButton
        :class="{'hidden': img || uploadStatus === 'pending'}"
        :disable-submit-button="uploadStatus === 'pending'"
        :show-submit="uploadStatus === 'success'"
        :align-left="true"
        v-on:skip="skipQuestion()"
        :is-mandatory="data.fields.isMandatory"
      ></SkipQuestionButton>      
    </footer>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'QRReader',

  transition: 'page',

  props: [ 'data' ],

  components: {
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    VueMarkdown,
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton'),
    Icons: () => import('Components/base/Icons'),
  },
  data () {
    return {
      isUploading: false,
      captures: [],
      camera: null,
      img: null,
      isLocked: false,
      uploadStatus: '',
      form: {
        type: 'picture',
        identifier: this.data.fields.identifier,
        decoded: false,
        question: this.data.fields.name,
        id: this.data.sys.id,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    },
  },
  methods: {
    handleFile(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.img = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async checkCaptchaAndUpload() {
      if (this.isUploading) {
        return;
      }
      this.isUploading = true;
      const uniqueID = uuidv4();
      const shortUUID = uniqueID.slice(0, 4);
      const timestamp = new Date().toISOString();
      const key = `s/${this.$store.state.survey.surveyId}/${this.$store.state.fingerprint}/${shortUUID}-${timestamp}.jpg`;
      let base64Data = this.img.split(",")[1];
      let decodedData = Buffer.from(base64Data, 'base64');
      this.$store.dispatch('UPLOAD_FILE_TO_S3', { 
        file: decodedData, 
        bucketName: 'sensata-s3',
        key: key,
        contentType: 'image/jpeg'
    }).then(url => {
        this.form.selected = [url];
        this.uploadStatus = 'success';
        this.img = '';
        this.$emit('next', this.form);
        this.isUploading = false;
      }).catch(err => {
        this.isUploading = false;
        alert(`Error: No internet.`);
        console.error("Failed to upload:", err);
      });
    },
    getBase64Size(base64String) {
      let length = base64String.length;
      return parseInt((length - 814) / 1.37);
    },
    reloadApp () {
      window.location.reload()
    },
    submitQuestion () {
      this.next()
    },
    submit () {
      if (this.validInput(this.cameraInput)) {
        this.form.selected = [this.cameraInput]
      }
    },
    skipQuestion () {
      this.fieldValue = 'S99'
      this.next()      
    },
    next () {
      this.arrows = false
      this.form.timestamps.finish = new Date()
      this.$emit('next', this.form)
    },
    validInput (value) {
      if (value && value.length >= 5) {
        this.form.timestamps.valid = new Date()
        this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.getDevices().then(() => {
      this.startCamera();
    });
  },
  unmounted () {
    this.$refs.camera.stop()
  },
  watch: {
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal[0])) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'cameraInput': function (newVal, oldVal) {
      if (this.validInput(newVal)) {
        this.disableSubmitButton = false
      } else {
        this.disableSubmitButton = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hidden {
  opacity: 0.0001;
}
footer {
  &.hidden {
    opacity: 0.0001;
  }
}
.image-wrapper {
    width: 100%; /* Or any specific width you want */
    height: 300px; /* Or any specific height you want */
    background-color: #f4f4f4; /* Background color to show empty space if image doesn't cover entire container */
    position: relative;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
}
.web-cam-wrapper {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  &.hidden {
    opacity: 0.0001;
  }
}
.web-cam-wrapper {
  // display: flex;
  // justify-content: center;
  // align-items: center;  
}

.actions-wrapper {
  display: flex;
  // flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  bottom: 0;
  left: 0;
  right: 0;
  & > div {
    position: relative;
  }
  h3 {
    margin-top: 0;
  }
}
.icon-animate:active {
  animation: standOutx 0.4s ease-in-out forwards;
}
@keyframes standOutx {
  0% { transform: scale(1.05) rotate(40deg) }
  20% { transform: scale(0.77) rotate(0deg) }
  100% { transform: scale(1.05) rotate(0deg) }
}

.image {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yet-another-wrapper {
  height: 45vh;
  //max-height: 300px;
  margin-bottom: auto;
  overflow: hidden;
}
.web-cam-wrapper {
  margin-bottom: auto;
  width: 100%;
  overflow: hidden;
}
.full-area {
  overflow: hidden;
  position: relative;
  max-height: 100vh;
  height: 100vh;
  background: transparent !important;

  @include breakpoint($tablet) {
    max-width: 500px;
    margin: auto;
  }

  .overlay {
    opacity: 1;
    z-index: 20;
    position: absolute;
    height: 100vh;
    width: 100vw;
    transition: all 0.2s ease-in-out;
    @include breakpoint($desktop) {
      transform: translateY(12vh);
    }

    &.hidden {
      background: transparent;
      z-index: -1;
    }
  }
}
.protocol {
  color: rgba(white, 0.3);
}

.qr-camera-wrapper {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  @include breakpoint($desktop) {
    transform: translateY(12vh);
  }
}
</style>
<style lang="scss">
.qr-camera-wrapper {
  .wrapper {
    padding: 0 !important;
    background: none !important;
  }
}
.el-button--primary {
  border-color: $color-wrapper-emphasis !important;
  background-color: $color-wrapper-emphasis !important;
}
.qr-input-field {
  .el-textarea {
    font-size: 2em;
    font-family: monospace;
    margin-bottom: 0.5em;
  }
  .el-textarea__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
  .el-input__inner {
    border: 1px solid $color-emphasis;
    text-align: center !important;
    font-size: 2em;
    padding: 1em;
    font-family: monospace;
  }
  .el-input__inner:focus {
    border-color: rgba($gray-medium, 0.4);
  }
}

</style>
<style scoped lang="scss">
h1 {
  color: $color-emphasis;
}
@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(1); }
  50% { transform: translate(-50%, -50%) scale(1.1); }
  100% { transform: translate(-50%, -50%) scale(1); }
}
.countdown-display {
  font-size: 3rem;
  font-weight: bold;
  color: rgba(93,36,102,0.9);
  text-shadow: 1px 1px 2px white, -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -95%);
  z-index: 10;
  animation: pulse 1s infinite;
}
video {
  width: 100%;
  height: auto;
  // border: 1px solid black;
}

canvas {
  display: none; // hide the canvas as you're just using it to capture the image.
}
</style>
